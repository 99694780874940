import { Box } from "@mui/material";
import solutionBg from "../../img/solution-bg.png";
import sarahAvatar from "../../img/sarah.svg";
import astridAvatar from "../../img/astrid.svg";
import InsertsNav from "../widgets/InsertsNav";
import { goToQuizPage } from "../../utils/quizPusher";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import useSWR from "swr";
import { REACT_APP_STRAPI_KEY, REACT_APP_STRAPI_URL } from "../../constants";
import Text from "../ui/Text";
import { Button } from "@mui/material";
import { AB_VERSION } from "../../constants";
import mixpanel from "mixpanel-browser";
import { useEffect } from "react";

const quizPageFetcher = (url) =>
  axios
    .get(url, { headers: { Authorization: `Bearer ${REACT_APP_STRAPI_KEY}` } })
    .then((res) => res.data.data);

function useQuizPage(qpid, emotionCategory) {
  const { data, error, isLoading } = useSWR(
    `${REACT_APP_STRAPI_URL}/quiz-pages/${qpid}`,
    quizPageFetcher
  );

  return {
    data,
    error,
    isLoading,
  };
}

const Solution = () => {
  const { emotionCategory } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const progress = Number(searchParams.get("p"));
  const qpid = searchParams.get("qpid");
  const { data, isLoading } = useQuizPage(qpid, emotionCategory);

  const header = data?.header;
  const prevQuizId = data?.previous_page?.id;
  const prevQuizType = data?.previous_page?.content.__component;

  const nextQuizId = data?.next_page?.id;
  const nextQuizType = data?.next_page.content.__component;

  const goToPrevQuestion = () => {
    goToQuizPage(navigate, emotionCategory, prevQuizType, prevQuizId, progress);
  };

  const goToNextQuestion = () => {
    goToQuizPage(navigate, emotionCategory, nextQuizType, nextQuizId, progress);
  };

  useEffect(() => {
    mixpanel.track(`$pageview:${emotionCategory}-solution`, {
      page: `${emotionCategory}-solution`,
      abVersion: AB_VERSION,
    });
  }, [emotionCategory]);

  return (
    <>
      <div
        className="bg-center h-screen bg-cover"
        style={{ backgroundImage: `url("${solutionBg}")` }}
      >
        <div className="flex flex-col items-center justify-center ">
          <div className="w-[468px] mt-3">
            <InsertsNav goToPrevQuestion={goToPrevQuestion} />
          </div>

          {isLoading && (
            <Text fontSize="20px" fontWeight={500} color="#212121">
              Loading your content...
            </Text>
          )}

          {!isLoading && (
            <Box
              sx={{
                width: { sm: "672px", xs: "100%" },
                paddingX: { sm: "0px", xs: "24px" },
                paddingBottom: "16px",
                paddingTop: "32px",
              }}
            >
              <Text
                textAlign="center"
                sx={{
                  marginTop: "40px",
                  margin: "auto",
                  width: "320px",
                  fontSize: { xs: "20px", sm: "24px" },
                  fontWeight: 500,
                  lineHeight: "38px",
                }}
              >
                {header}
              </Text>

              <Box
                sx={{
                  marginY: "32px",
                  padding: "32px",
                  bgcolor: "white",
                  borderRadius: "24px",
                }}
              >
                <Text
                  sx={{
                    fontSize: { xs: "20px", sm: "24px" },
                    fontWeight: 500,
                    lineHeight: "38px",
                    textAlign: "center",
                  }}
                >
                  Our lessons are specially crafted by leading behavioral
                  scientists
                </Text>

                <Box
                  className="flex-col md:flex-row"
                  sx={{
                    marginY: "40px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "30px",
                  }}
                >
                  <Box
                    sx={{
                      flex: 1,
                      width: "276px",
                    }}
                  >
                    <img
                      className="m-auto"
                      alt="sarah"
                      src={sarahAvatar}
                      width={97}
                      height={120}
                    />
                    <Text
                      sx={{
                        fontWeight: 500,
                        fontSize: "22px",
                        marginBottom: "10px",
                      }}
                    >
                      Sarah Stein Lubrano PhD
                    </Text>
                    <Text
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        width: "95%",
                        margin: "0 auto",
                      }}
                    >
                      Head of Content at{" "}
                      <span style={{ fontWeight: "500" }}>
                        The School of Life,
                      </span>{" "}
                      PhD in Behavioral Science at{" "}
                      <span style={{ fontWeight: "500" }}>
                        Oxford University,
                      </span>{" "}
                      Previously{" "}
                      <span style={{ fontWeight: "500" }}>
                        Harvard University & University of Cambridge
                      </span>{" "}
                    </Text>
                  </Box>
                  <Box sx={{ flex: 1, width: "276px" }}>
                    <img
                      className="m-auto"
                      alt="astrid"
                      src={astridAvatar}
                      width={97}
                      height={120}
                    />
                    <Text
                      sx={{
                        fontWeight: 500,
                        fontSize: "22px",
                        marginBottom: "10px",
                      }}
                    >
                      Dr. Astrid Junghans
                    </Text>
                    <Text
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        width: "95%",
                        margin: "0 auto",
                      }}
                    >
                      Behavior Change Psychologist at{" "}
                      <span style={{ fontWeight: "500" }}>Nudge/Inc,</span>{" "}
                      Scientific Director at{" "}
                      <span style={{ fontWeight: "500" }}>FountLabs</span>
                    </Text>
                  </Box>
                </Box>
              </Box>

              <div className="mx-16">
                <Button
                  className="btn-cta"
                  variant="contained"
                  color="primary"
                  sx={{
                    fontWeight: 500,
                    height: "64px",
                    fontSize: { xs: "14px", sm: "18px" },
                    paddingY: { xs: "12px", sm: "20px" },
                    marginBottom: "5px",
                  }}
                  fullWidth
                  onClick={goToNextQuestion}
                >
                  <Text fontSize="18px" fontWeight={500} color="#ffffff">
                    Continue
                  </Text>
                </Button>
              </div>
            </Box>
          )}
        </div>
      </div>
    </>
  );
};

export default Solution;
