import { Box } from "@mui/material";
import JourneyCard from "./ui/JourneyCard";
import Text from "./ui/Text";
import axios from "axios";
import JourneyCardSkeleton from "./ui/JourneyCardSkeleton";
import { useEffect, useState } from "react";
import { REACT_APP_STRAPI_KEY, REACT_APP_STRAPI_URL } from "../constants";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { AB_VERSION } from "../constants";

const JourneySelect = () => {
  const [emotionList, setEmotionList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    mixpanel.track(`$pageview-sat:journeys`, {
      page: `journeys`,
      abVersion: AB_VERSION,
    });

    const getEmotions = () => {
      let url = REACT_APP_STRAPI_URL + "/emotion-categories?sort[0]=id:asc";

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${REACT_APP_STRAPI_KEY}`,
          },
        })
        .then((res) => {
          const response = res.data.data;

          const emotionList =
            response.map((e) => ({
              emotionId: e.id,
              slug: e.name_slug,
              header: e.header,
              subheader: e.subheader,
              heroImg: e.logo.url,
              name: e.name,
            })) ?? [];

          setEmotionList(emotionList);
        })
        .catch((error) => {
          console.warn("unable to fetch emotion list", error);
        });
    };

    getEmotions();
  }, []);

  return (
    <div className="flex items-center justify-center mt-3">
      <div className="w-[468px]">
        <div className="text-center mt-12">
          <Text fontSize="28px" fontWeight={500} color="#212121">
            Where would you like to start?
          </Text>
          <Text fontSize="28px" fontWeight={500} color="#212121">
            Select a topic to explore.
          </Text>

          <Box
            sx={{
              width: {
                md: "468px",
                sm: "calc(100% - 80px)",
                xs: "100%",
              },
              marginX: "auto",
              marginTop: "3rem",
              paddingX: { xs: "24px", sm: "0px" },
            }}
          >
            {emotionList.length === 0 && (
              <>
                <JourneyCardSkeleton />
                <JourneyCardSkeleton />
                <JourneyCardSkeleton />
              </>
            )}

            {emotionList.map((e) => (
              <JourneyCard
                key={e.emotionId}
                imgUrl={e.heroImg}
                isSelected={e.isSelected}
                title={e.header}
                desc={e.subheader}
                onClick={() => {
                  navigate(`/${e.slug}/intro`);
                }}
              />
            ))}
          </Box>
        </div>
      </div>
      <Text
        sx={{
          fontSize: { xs: "14px", sm: "16px" },
          textAlign: "center",
          width: "100%",
          marginX: "auto",
          marginBottom: "10px",
          fontWeight: 500,
          color: "rgba(0, 0, 0, 0.6)",
          position: "fixed",
          bottom: "0",
        }}
      >
        Powered by 🖤 ahead app
      </Text>
    </div>
  );
};

export default JourneySelect;
