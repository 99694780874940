import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./components/Home";
import Success from "./components/Success";

import FinalResult from "./components/FinalResult";
import FeedbackStart from "./components/feedback/FeedbackStart";
import FeedbackQuestion from "./components/feedback/FeedbackQuestion";
import JourneySelect from "./components/JourneySelect";
import EmotionIntro from "./components/emotionCategory/EmotionIntro";
import DeviceCheck from "./components/emotionCategory/DeviceCheck";
import Waitlist from "./components/Waitlist";
import Question from "./components/emotionCategory/Question";
import ProgressGraph from "./components/emotionCategory/ProgressGraph";
import Review from "./components/emotionCategory/Review";
import Solution from "./components/emotionCategory/Solution";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/feedback-start/:id" exact element={<FeedbackStart />} />
        <Route path="/feedback-test/:id" exact element={<FeedbackQuestion />} />
        <Route path="/success/:id" exact element={<Success />} />
        <Route path="/final-result/:id" exact element={<FinalResult />} />
        <Route path="/journeys" exact element={<JourneySelect />} />
        <Route
          path="/:emotionCategory/intro"
          exact
          element={<EmotionIntro />}
        />
        <Route path="/:emotionCategory/question" exact element={<Question />} />
        <Route
          path="/:emotionCategory/progress-graph"
          exact
          element={<ProgressGraph />}
        />
        <Route path="/:emotionCategory/review" exact element={<Review />} />

        <Route
          path="/:emotionCategory/device"
          exact
          element={<DeviceCheck />}
        />
        <Route path="/:emotionCategory/solution" exact element={<Solution />} />

        <Route path="/waitlist" exact element={<Waitlist />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
